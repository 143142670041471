import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/components/datatable-additional-action/DatatableAdditionalAction';
import { FilterType, SelectFilter } from '@/components/filters-bar/FilterBar.type';
import { FiltersBar } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getSelectFilterNumberValues, getSelectFilterStringValuesByKey } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { UnitType } from '@/domain/date/Date.model';
import { Employment } from '@/domain/employment/Employment.model';
import { convertLeavesMinutesToUnit } from '@/domain/leave-request/LeaveRequest.service';
import { EmployeeLeaveTypeBalance, LeaveBalanceSearchRequest } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { AllowanceType, CarryoverType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { getCycleDates } from '@/domain/leave-type/LeaveType.service';
import { canCorrectOtherEmployeeBalance } from '@/domain/permission/Permission.service';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { getCycleStartDate, isDifferentLifeCycleStartMonth } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { useLeaveBalancePageFilters } from '@/hooks/leave-type-history/LeaveBalancePageFilters.hook';
import { useGetLeaveBalance } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { AssignPolicyDialog } from '@/page/employee-profile/employee-profile-leave/AssignPolicyDialog';
import { UnassignPolicyDialog } from '@/page/employee-profile/employee-profile-leave/UnassignPolicyDialog';
import { useCurrentPolicies } from '@/stores/store';
import { isDefined } from '@/utils/collections.util';
import { formatDate, formatInDefaultDate, getEndOfYear, MONTHS } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, Paper, Stack, Theme, useTheme } from '@mui/material';
import { ColDef, RowClickedEvent, SortChangedEvent } from 'ag-grid-enterprise';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type LeavesBalanceGridProps = {
    leaveTypes: LeaveType[];
    defaultLeaveType: LeaveType;
    timesheetSettings: TimesheetSetting[];
    defaultTimesheetSetting: TimesheetSetting;
    currentEmployeeId: number;
};

export const LeavesBalanceGrid: FC<LeavesBalanceGridProps> = ({
    leaveTypes,
    defaultLeaveType,
    timesheetSettings,
    defaultTimesheetSetting,
    currentEmployeeId,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const policies = useCurrentPolicies();
    const canCorrectSomeEmployeeBalance = canCorrectOtherEmployeeBalance(policies, currentEmployeeId);
    const haveDifferentCycleStartMonth = isDifferentLifeCycleStartMonth(timesheetSettings);

    const [employeeIdsToAssign, setEmployeeIdsToAssign] = useState<number[]>([]);
    const [employeeIdsToUnAssign, setEmployeeIdsToUnAssign] = useState<number[]>([]);

    // FILTERS
    const { filters: availableFilters } = useLeaveBalancePageFilters(
        leaveTypes,
        defaultLeaveType,
        timesheetSettings,
        defaultTimesheetSetting,
        haveDifferentCycleStartMonth,
    );

    const [filters, setFilters] = useLeaveBalanceFiltersStorage('manage-balance-leaves-filters', availableFilters);

    const setFilterConditions = (newFilters: FilterType[]) => {
        const copyFilters = [...newFilters];
        const leaveTypeId = getFilterValueIdsByKey(copyFilters, 'leaveTypeId')?.[0];
        const leaveType = leaveTypes.find(leaveType => leaveType.id === leaveTypeId);

        const newCycleMonth = (getSelectFilterStringValuesByKey(newFilters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        const oldCycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        //if the cycle month changes, we need to update the endDate so that we have the current cycle and not a future one
        const updateEndDate = newCycleMonth !== oldCycleMonth;

        copyFilters.forEach(filter => {
            if (leaveType && filter.key === 'displayInUnitType') {
                filter.hide = leaveType.displayUnitType !== UnitType.BOTH;
            }
            if (filter.key === 'endCycle') {
                const { startDate, endDate } = getCycleDates(getCycleEndDate(newFilters), newCycleMonth, updateEndDate);
                filter.filterName = formatInDefaultDate(startDate);
                if (updateEndDate) {
                    filter.value = endDate;
                }
            }
        });

        setFilters(copyFilters);
    };

    const search: LeaveBalanceSearchRequest = {
        leaveTypeId: getLeaveTypeId(filters),
        endDate: getCycleEndDate(filters),
        locationIds: getFilterValueIdsByKey(filters, 'locationIds'),
        departmentIds: getFilterValueIdsByKey(filters, 'departmentIds'),
        jobIds: getFilterValueIdsByKey(filters, 'jobIds'),
        managerIds: getFilterValueIdsByKey(filters, 'managerIds'),
        cycleStartMonth: haveDifferentCycleStartMonth ? (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] as MONTHS) : undefined,
    };

    const { data: leaveBalance = [], isFetching = false } = useGetLeaveBalance(search, {
        options: { enabled: !!filters.length },
    });

    const theme = useTheme();

    const leaveType = getLeaveType(leaveTypes, filters, defaultLeaveType);

    const cycleEndDate = getCycleEndDate(filters);
    const displayUnitType = getDisplayUnitType(filters);

    const columnDefs = getColumnDefs(theme, leaveType, cycleEndDate, displayUnitType);

    const agGridWrapper = useAgGridWrapper<EmployeeLeaveTypeBalance>();

    const onBtnExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            // We don't want to export hidden columns
            allColumns: false,
            // If we don't provide columnKeys, it will  add an empty column for row selection (I suppose)
            columnKeys: columnDefs.map(columnDef => columnDef.colId ?? columnDef.field).filter(isDefined),
        });
    };

    const handleRowClicked = (params: RowClickedEvent<EmployeeLeaveTypeBalance>) => {
        if (!params?.event?.defaultPrevented && params.data) {
            navigate(`/profile/${params.data.employee.id}/leaves/history/${params.data.leaveType.id}`);
        }
    };

    const onSortChange = (params: SortChangedEvent<EmployeeLeaveTypeBalance>) => {
        const sortModel = params.api.getColumnState();
        localStorage.setItem('sortLeaves', JSON.stringify(sortModel));
    };

    const selectedRows = agGridWrapper?.selectedRows;

    return (
        <Stack gap={2} flex={1}>
            <Stack
                component={Paper}
                p={{ xs: 2, sm: 1 }}
                pb={{ xs: 0, sm: 1 }}
                direction={'row'}
                spacing={2}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <FiltersBar filters={filters} onFiltersChange={setFilterConditions} />
                <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} onBtnExport={onBtnExport} disabled={isFetching} />
            </Stack>
            <Stack flex='1'>
                <AgGridWrapper<EmployeeLeaveTypeBalance>
                    rowData={leaveBalance}
                    rowSelection={
                        canCorrectSomeEmployeeBalance
                            ? {
                                  mode: 'multiRow',
                              }
                            : undefined
                    }
                    initRef={agGridWrapper.setGridRef}
                    onSortChanged={onSortChange}
                    onRowClicked={handleRowClicked}
                    columnDefs={columnDefs}
                    // This is useful to keep selection after front end filtering
                    getRowId={({ data }) => data.employee?.id?.toString()}
                    loading={isFetching}
                    toolbarActions={
                        <Stack direction='row' gap={1}>
                            <Button
                                onClick={() => {
                                    setEmployeeIdsToAssign(selectedRows.map(r => r.employee.id) ?? []);
                                }}
                            >
                                {t('balance_page.assign_leave_type')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setEmployeeIdsToUnAssign(selectedRows.map(r => r.employee.id) ?? []);
                                }}
                            >
                                {t('balance_page.unassign_leave_type')}
                            </Button>
                        </Stack>
                    }
                />
            </Stack>
            {!!employeeIdsToAssign.length && (
                <AssignPolicyDialog
                    onPolicyAssigned={() => {
                        setEmployeeIdsToAssign([]);
                    }}
                    onClose={() => {
                        setEmployeeIdsToAssign([]);
                    }}
                    employeeIds={employeeIdsToAssign}
                />
            )}

            {!!employeeIdsToUnAssign.length && (
                <UnassignPolicyDialog
                    userLeaveTypePolicies={[]}
                    onPolicyUnassigned={() => {
                        setEmployeeIdsToUnAssign([]);
                    }}
                    onClose={() => {
                        setEmployeeIdsToUnAssign([]);
                    }}
                    employeeIds={employeeIdsToUnAssign}
                />
            )}
        </Stack>
    );
};

const getCycleEndDate = (filters: FilterType[]): LocalDate => {
    const filterEndDate = filters.find(filter => filter.key === 'endCycle')?.value as LocalDate | undefined;
    return filterEndDate ?? getEndOfYear();
};
const getDisplayUnitType = (filters: FilterType[]): UnitType => {
    const filter = filters.find((filter): filter is SelectFilter => filter.key === 'displayInUnitType');
    const filterValue = filter?.value?.[0].value as UnitType | undefined;
    const unitType = filterValue;
    return unitType ?? UnitType.DAYS;
};

const getLeaveTypeId = (filters: FilterType[]) => {
    return getSelectFilterNumberValues(filters.find(filter => filter.key === 'leaveTypeId'))?.[0];
};

const getLeaveType = (leaveTypes: LeaveType[], filters: FilterType[], defaultLeaveType: LeaveType) => {
    const leaveTypeId = getLeaveTypeId(filters);
    return leaveTypes.find(leaveType => leaveType.id === leaveTypeId) ?? defaultLeaveType;
};

const getColumnDefs = (
    theme: Theme,
    selectedLeaveType: LeaveType,
    selectedCycleEndDate: LocalDate,
    unitTypeForBothUnitType: UnitType,
): ColDef<EmployeeLeaveTypeBalance>[] => {
    return [
        {
            field: 'employee.employeeCode',
            headerName: i18next.t('employee.employee_code'),
            hide: true,
        },
        {
            field: 'employee',
            type: 'employee',
            headerName: i18next.t('general.employee'),
        },
        {
            field: 'employee.currentEmployments',
            colId: 'jobTitle',
            headerName: i18next.t('balance_page.jobTitle'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => getLabelTranslation(employment.job.name)).join(', '),
        },
        {
            field: 'employee.currentEmployments',
            colId: 'location',
            headerName: i18next.t('balance_page.location'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => employment.location.name).join(', '),
        },
        {
            field: 'employee.currentEmployments',
            colId: 'department',
            headerName: i18next.t('balance_page.department'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => getLabelTranslation(employment.department.name)).join(', '),
        },
        {
            field: 'employee.currentEmployments',
            colId: 'manager',
            headerName: i18next.t('balance_page.manager'),
            valueGetter: ({ data }) => data?.employee.currentEmployments?.flatMap(employment => employment.managers),
            type: 'stackedAvatars',
        },
        {
            field: 'cycleHistory.remainingBalanceInMinutes',
            headerName: i18next.t('balance_page.current_balance', { date: formatDate(selectedCycleEndDate, 'dd.MM') }),
            cellStyle: ({ value }) => {
                return value < 0 ? { color: theme.palette.error.main } : undefined;
            },
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                const unitType = data.leaveType?.displayUnitType === UnitType.BOTH ? unitTypeForBothUnitType : data.leaveType?.displayUnitType;
                return convertLeavesMinutesToUnit({
                    input: unitType === UnitType.DAYS ? data.cycleHistory.remainingBalanceInDays : data.cycleHistory.remainingBalanceInMinutes,
                    outputUnit: unitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
        },
        {
            field: 'cycleHistory.grantedAmountInMinutes',
            headerName: i18next.t('balance_page.grant'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                const unitType = data.leaveType?.displayUnitType === UnitType.BOTH ? unitTypeForBothUnitType : data.leaveType?.displayUnitType;
                return convertLeavesMinutesToUnit({
                    input:
                        unitType === UnitType.DAYS
                            ? data.cycleHistory.grantedAmountInDays - data.cycleHistory.carryoverAmountInDays
                            : data.cycleHistory.grantedAmountInMinutes - data.cycleHistory.carryoverAmountInMinutes,
                    outputUnit: unitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
            hide: selectedLeaveType?.allowanceType === AllowanceType.UNLIMITED,
        },
        {
            field: 'cycleHistory.carryoverAmountInMinutes',
            headerName: i18next.t('balance_page.carryover'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                const unitType = data.leaveType?.displayUnitType === UnitType.BOTH ? unitTypeForBothUnitType : data.leaveType?.displayUnitType;
                return convertLeavesMinutesToUnit({
                    input: unitType === UnitType.DAYS ? data.cycleHistory.carryoverAmountInDays : data.cycleHistory.carryoverAmountInMinutes,
                    outputUnit: unitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
            hide: selectedLeaveType?.carryoverType === CarryoverType.YEARLY_NO_CARRYOVER || selectedLeaveType?.allowanceType === AllowanceType.UNLIMITED,
        },
        {
            field: 'cycleHistory.takenAmountInMinutes',
            headerName: i18next.t('balance_page.taken_days'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                const leaveType = data.leaveType;
                const unitType = leaveType?.displayUnitType === UnitType.BOTH ? unitTypeForBothUnitType : leaveType?.displayUnitType;
                const amount = unitType === UnitType.DAYS ? data.cycleHistory.takenAmountInDays : data.cycleHistory.takenAmountInMinutes;
                return convertLeavesMinutesToUnit({
                    input: leaveType.allowanceType === AllowanceType.UNLIMITED ? amount : -amount,
                    outputUnit: unitType,
                    roundingType: leaveType.roundingType,
                });
            },
        },
        {
            field: 'cycleHistory.plannedAmountInMinutes',
            headerName: i18next.t('balance_page.planned'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                const leaveType = data.leaveType;
                const unitType = leaveType?.displayUnitType === UnitType.BOTH ? unitTypeForBothUnitType : leaveType?.displayUnitType;
                const amount = unitType === UnitType.DAYS ? data.cycleHistory.plannedAmountInDays : data.cycleHistory.plannedAmountInMinutes;
                return convertLeavesMinutesToUnit({
                    input: leaveType.allowanceType === AllowanceType.UNLIMITED ? amount : -amount,
                    outputUnit: unitType,
                    roundingType: leaveType.roundingType,
                });
            },
        },
        {
            field: 'cycleHistory.adjustedAmountInMinutes',
            headerName: i18next.t('balance_page.adjustments'),
            valueGetter: ({ data }) => {
                if (!data) {
                    return;
                }
                const unitType = data.leaveType?.displayUnitType === UnitType.BOTH ? unitTypeForBothUnitType : data.leaveType?.displayUnitType;
                return convertLeavesMinutesToUnit({
                    input: unitType === UnitType.DAYS ? data.cycleHistory.adjustedAmountInDays : data.cycleHistory.adjustedAmountInMinutes,
                    outputUnit: unitType,
                    roundingType: data.leaveType.roundingType,
                });
            },
            hide: selectedLeaveType?.allowanceType === AllowanceType.UNLIMITED,
        },
    ];
};

/**
 * hook to manage the filter name for the cycle. Especially here, we need to rename the endCycle filter name
 * @param key
 * @param availableFilters
 */
const useLeaveBalanceFiltersStorage = (key: string, availableFilters: FilterType[]): [FilterType[], (filters: FilterType[]) => void] => {
    const [filters, setFilters] = useFiltersStorage(key, availableFilters);

    const filtersUpdated = filters.map(filter => {
        if (filter.key !== 'endCycle') {
            return filter;
        }
        const cycleMonth = (getSelectFilterStringValuesByKey(filters, 'startCycleMonth')?.[0] ?? MONTHS.JANUARY) as MONTHS;
        const endDate = getCycleEndDate(filters);
        return { ...filter, filterName: formatInDefaultDate(getCycleStartDate(endDate, cycleMonth)) };
    });
    return [filtersUpdated, setFilters];
};
